import Script from 'next/script';

export default function Insider() {
  if (process.env.NEXT_PUBLIC_FEATURE_FLIP_INSIDER_ENABLED !== 'true') {
    return null;
  }

  return (
    <Script
      id="insider-script"
      strategy="afterInteractive"
      async
      src={`//${process.env.NEXT_PUBLIC_MARKETING_INSIDER_PARTNER_NAME}.api.useinsider.com/ins.js?id=${process.env.NEXT_PUBLIC_MARKETING_INSIDER_PARTNER_ID}`}
    />
  );
}
