import Script from 'next/script';

export default function CookiesPolicy() {
  if (process.env.NEXT_PUBLIC_FEATURE_FLIP_COOKIES_POLICY_ENABLED !== 'true') {
    return null;
  }

  return (
    <Script
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-domain-script={`f919e144-3bfc-4d9b-9c90-9306953cbd00${
        process.env.NODE_ENV === 'production' ? '' : '-test'
      }`}
    />
  );
}
