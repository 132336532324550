import { Suspense } from 'react';

import { DownloadApp, Shell } from './components';

function PromotionalBox() {
  return (
    <Suspense fallback={<Shell />}>
      <DownloadApp />
    </Suspense>
  );
}

export default PromotionalBox;
