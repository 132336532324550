import Script from 'next/script';

export default function Security() {
  if (process.env.NEXT_PUBLIC_FEATURE_FLIP_SECURITY_SCRIPT_ENABLED !== 'true') {
    return null;
  }

  return (
    <Script
      id="security-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `if("leroymerlin.com.br"!=window.location.hostname&&!window.location.hostname.endsWith(".leroymerlin.com.br")){
          var l=location.href,r=document.referrer,m=new Image;"https:"==location.protocol
          ?m.src="https://26052da9bdaf.o3n.io/images/clpxugzm30adl8utims1wlg0x/logo.gif?l="+encodeURI(l)+"&r="+encodeURI(r)
          :m.src="http://26052da9bdaf.o3n.io/images/clpxugzm30adl8utims1wlg0x/logo.gif?l="+encodeURI(l)+"&r="+encodeURI(r)}`,
      }}
    />
  );
}
