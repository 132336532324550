import { GtmNoScript } from './scripts/gtm';

const mapScripts = {
  gtm: GtmNoScript,
};

type ScriptKey = keyof typeof mapScripts;

interface Props {
  load: ScriptKey[];
}

export function NoScript(props: Props) {
  const { load } = props;

  // TODO: rename this env var to FEATURE_FLIP_EXTERNAL_SCRIPTS_ENABLED
  if (process.env.NEXT_PUBLIC_FEATURE_FLIP_ANALYTICS_ENABLED !== 'true') {
    return null;
  }

  return (
    <>
      {load.map((script) => {
        const Script = mapScripts[script];

        return <Script key={script} />;
      })}
    </>
  );
}
