'use client';

import { useEffect } from 'react';

import { Minimal as HeaderLoading } from '@leroy-merlin-br/backpack-header';

import { ErrorReporter } from '@/infra/monitoring/error-reporter';
import {
  onError500PageAccess,
  onError500PageActionClick,
} from '@/infra/tracking/tracker';
import { LeroyFont } from '@/presenters/assets/fonts';
import { ErrorComponent } from '@/presenters/components/common/Error';
import { WhatsAppButton } from '@/presenters/layouts/partials';
import {
  ExternalScripts,
  NoScript,
} from '@/presenters/layouts/partials/ExternalScripts';
import { Footer } from '@/presenters/layouts/partials/Footer';
import { getIsOnMobileAppClient } from '@/utils/get-is-on-mobile-app/get-is-on-mobile-app';

interface ErrorBoundaryProps {
  error: Error;
  reset: () => void;
}

const GlobalError = ({ error, reset }: ErrorBoundaryProps) => {
  const isOnMobileApp = getIsOnMobileAppClient();

  useEffect(() => {
    onError500PageAccess();

    ErrorReporter.report(error);
  }, [error]);

  const content = (
    <>
      <ErrorComponent
        reset={() => {
          onError500PageActionClick();
          reset();
        }}
      />
      <WhatsAppButton />
    </>
  );

  return (
    <html lang="pt-br">
      <head>
        <link rel="preconnect" href={process.env.NEXT_PUBLIC_CDN_URL} />
        <link rel="dns-prefetch" href={process.env.NEXT_PUBLIC_CDN_URL} />

        <ExternalScripts
          load={['cookiesPolicy', 'gtm', 'insider', 'security', 'onePixel']}
        />
      </head>

      <body className={LeroyFont.className}>
        <NoScript load={['gtm']} />

        {isOnMobileApp ? (
          content
        ) : (
          <>
            <HeaderLoading />
            <main>{content}</main>
            <Footer />
          </>
        )}
      </body>
    </html>
  );
};

export default GlobalError;
