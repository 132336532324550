/* eslint-disable @next/next/next-script-for-ga */
/**
 * Estamos utilizando a tag `script` do html em vez do `Script` do Next.js,
 * pois a prop `strategy="afterInteractive"` do Next.js faz com que o script fique
 * no final do body, o que não é o recomendado para o GTM.
 * Poderíamos utilizar `strategy="beforeInteractive"`,
 * mas o lint também reclama de utilizar essa `strategy` no app router do Next.js.
 */

export default function Gtm() {
  if (process.env.NEXT_PUBLIC_FEATURE_FLIP_GTM_ENABLED !== 'true') {
    return null;
  }

  return (
    <script
      async
      id="gtm-ecommerce-script"
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_MARKETING_GTM_ID}');
        `,
      }}
    />
  );
}

export function GtmNoScript() {
  if (process.env.NEXT_PUBLIC_FEATURE_FLIP_GTM_ENABLED !== 'true') {
    return null;
  }

  return (
    <>
      <noscript>
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_MARKETING_GTM_ID}`}
          height="0"
          width="0"
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
        />
      </noscript>
    </>
  );
}
