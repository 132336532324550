import cookiesPolicy from './scripts/cookies-policy';
import gtm from './scripts/gtm';
import insider from './scripts/insider';
import onePixel from './scripts/one-pixel';
import security from './scripts/security';

const mapScripts = {
  cookiesPolicy,
  gtm,
  insider,
  onePixel,
  security,
};

type ScriptKey = keyof typeof mapScripts;

interface ExternalScriptsProps {
  load: ScriptKey[];
}

export function ExternalScripts(props: ExternalScriptsProps) {
  const { load } = props;

  // TODO: rename this env var maybe to FEATURE_FLIP_EXTERNAL_SCRIPTS_ENABLED
  if (process.env.NEXT_PUBLIC_FEATURE_FLIP_ANALYTICS_ENABLED !== 'true') {
    return null;
  }

  return (
    <>
      {load.map((script) => {
        const Script = mapScripts[script];

        return <Script key={script} />;
      })}
    </>
  );
}
